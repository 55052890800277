// Conveniently import this file anywhere to use db

import firebase from "firebase/app";
import "firebase/database";
import store from "@/store/index";
import moment from "moment";
import {
  iniciarbd,
  guardar_datos,
  leer_datos,
  leer_datos_montura,
} from "./bd_local";

export const db = firebase.initializeApp({
  apiKey: "AIzaSyClYN-cOjFKLcc-MxpY11yhvikOutzES04",
  authDomain: "opticenter-vision.firebaseapp.com",
  databaseURL: "https://opticenter-vision-default-rtdb.firebaseio.com",
  projectId: "opticenter-vision",
  storageBucket: "opticenter-vision.appspot.com",
  messagingSenderId: "1081228075399",
  appId: "1:1081228075399:web:66d32d43f4c93e70d6f322",
  measurementId: "G-CM9MEDKXZ3",
});

const empresas = db.database().ref("empresas");
const notificaciones = db.database().ref("notificaciones");

export const leer_local = (tabla) => {
  return leer_datos(tabla);
};
export const leer_local_montura = (tabla, ubicacion) => {
  return leer_datos_montura(tabla, ubicacion);
};
export const guardar_local = (tabla, datos) => {
  return guardar_datos(tabla, datos);
};
export const allEmpresas = () => {
  return empresas;
};

export const guardar_ip = (id) => {
  var arr = {
    estado: "pendiente",
    ip: id,
    nombre: "",
  };
  return db
    .database()
    .ref("lista_ip")
    .child(id)
    .set(arr);
};
export const busca_ip = (id) => {
  return db
    .database()
    .ref("lista_ip")
    .child(id);
};
export const editar_ip = (id, arr) => {
  return db
    .database()
    .ref("lista_ip")
    .child(id)
    .set(arr);
};
export const elimina_ip = (id, arr) => {
  return db
    .database()
    .ref("lista_ip")
    .child(id)
    .remove();
};
export const all_ip = () => {
  return db.database().ref("lista_ip");
};
export const nuevaEmpresa = (id, array) => {
  return empresas.child(id).set(array);
};

export const eliminaEmpresa = (id) => {
  return empresas.child(id).remove();
};

export const actualizaEmpresa = (id, array) => {
  return empresas.child(id).set(array);
};

//----------------empresa---------------------
export const obtenerBD = (empresa) => {
  return empresas.child(empresa);
};
export const allConfigura = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("configuracion");
};
export const grabaConfigura = (configura, value) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("configuracion")
    .child(configura)
    .set(value)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};

export const grabaConfiguraImpresora = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("configuracion")
    .child("impresoras")
    .child(id)
    .set(array);
};
export const allConfiguraImpresora = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("configuracion")
    .child("impresoras");
};
export const eliminaConfiguraImpresora = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("configuracion")
    .child("impresoras")
    .child(id)
    .remove();
};
//----------------empresa---------------------
export const obtenerImpresoras = () => {
  return db
    .database()
    .ref("general")
    .child("impresora");
};
export const actualizaImpresoras = (tipo, dato) => {
  return db
    .database()
    .ref("general")
    .child("impresora")
    .child(tipo)
    .set(dato);
};
//----------------series---------------------
export const obtenerSeries = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("seriesdocumentos");
};
export const actualizaSeries = (tipo, dato) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("seriesdocumentos")
    .child(tipo)
    .set(dato);
};

//----------------und Medidas---------------------
export const allMedidas = (bd) => {
  return db
    .database()
    .ref(bd)
    .child("undmedida");
};
export const nuevaMedidas = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("undmedida")
    .child(id)
    .set(array);
};
export const eliminaMedidas = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("undmedida")
    .child(id)
    .remove();
};

//-------------entradas------------
export const allEntradas = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("entradas");
};
export const nuevoEntradas = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("entradas")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const eliminaEntradas = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("entradas")
    .child(id)
    .remove();
};
export const buscaEntradas = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("entradas");
};
//-------------entradas------------
export const allProformas = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proformas");
};
export const nuevoProformas = (array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proformas")
    .push(array);
};
export const eliminaProformas = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proformas")
    .child(id)
    .remove();
};
export const editaProformas = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proformas")
    .child(id)
    .set(array);
};
export const buscaProformas = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proformas");
};
export const buscaProformas_ext = (bd, id) => {
  return db
    .database()
    .ref(bd)
    .child("kardex")
    .child("proformas")
    .child(id);
};
//-------------COMPRAS------------
export const allCompras = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("compras");
};
export const nuevoCompras = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("compras")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const eliminaCompras = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("compras")
    .child(id)
    .remove();
};
export const buscaCompras = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("compras");
};
//-------------salidas------------
export const allSalidas = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("salidas");
};
export const nuevoSalidas = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("salidas")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const eliminaSalidas = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("salidas")
    .child(id)
    .remove();
};
export const buscaSalidas = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("salidas");
};
//---------crear masivos---------
export const crearCampo = (bd, id, nombre, valor) => {
  return db
    .database()
    .ref(bd)
    .child("productos")
    .child(id)
    .child(nombre)
    .set(valor);
};
export const crearCampoconfiguracion = (bd, tabla, nombre, valor) => {
  return db
    .database()
    .ref(bd)
    .child(tabla)
    .child(nombre)
    .set(valor);
};
export const crearCampoCATEGORIAS = (bd, tabla, id, nombre, valor) => {
  return db
    .database()
    .ref(bd)
    .child(tabla)
    .child(id)
    .child(nombre)
    .set(valor);
};
//----------------contadores---------------------
export const obtenContador = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("contadores");
};
export const sumaContador = (contador, orden) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("contadores")
    .child(contador)
    .set(orden);
};
export const actualizaContador = (contador, orden) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("contadores")
    .child(contador)
    .set(orden);
};
//----------------categorias---------------------
export const allCategorias = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("categorias");
};
export const nuevoCategoria = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("categorias")
    .child(id)
    .set(array);
};
export const editaCategoria = (id, campo, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("categorias")
    .child(id)
    .child(campo)
    .set(data);
};
export const eliminaCategoria = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("categorias")
    .child(id)
    .remove();
};
//----------------categorias---------------------
export const allSeries = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("series");
};
export const nuevoSeries = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("series")
    .child(id)
    .set(array);
};
export const eliminaSeries = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("series")
    .child(id)
    .remove();
};
//-----------Comprobante Venta--------------------
export const consulta_Cabecera = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(id);
};
export const eliminaNodo = (nodo) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child(nodo)
    .remove();
};
export const allCabecera = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera");
};
export const grabaCabecera = (numeracion, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const grabaDetalle = (numeracion, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantedetalle")
    .child(numeracion)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const consultaDetalle_bd = (bd, numeracion) => {
  return db
    .database()
    .ref(bd)
    .child("comprobantedetalle")
    .child(numeracion);
};
export const consultaDetalle = (numeracion) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantedetalle")
    .child(numeracion);
};
export const allCabecera_general = (bd) => {
  return db
    .database()
    .ref(bd)
    .child("comprobantecabecera");
};
export const grabaEstadoComprobante = (
  numeracion,
  personaID,
  estado,
  data,
  hash
) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("documentId")
    .set(personaID);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("estado")
    .set(estado);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("mensajeSunat")
    .set(data);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("hash")
    .set(hash);
};
export const grabaconsultacomprobante = (numeracion, estado, data) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("estado")
    .set(estado);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("mensajeSunat")
    .set(data);
};
export const grabaDatoC = (numeracion, campo, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child(campo)
    .set(data);
};
export const grabaconsultacomprobanteNC = (numeracion, estado, data) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("estado")
    .set(estado);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("mensajeSunat")
    .set(data);
};

export const grabaEstadoTicketConsolidado = (numeracion, consolidado) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("estado")
    .set("consolidado");
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("documentId")
    .set(consolidado);
};
export const buscacabecera = (bd, numeracion) => {
  return db
    .database()
    .ref(bd)
    .child("comprobantecabecera")
    .child(numeracion);
};
export const buscadetalle = (bd, numeracion) => {
  return db
    .database()
    .ref(bd)
    .child("comprobantedetalle")
    .child(numeracion);
};
//------------- puntos clientes /////
export const buscaPuntosCliente = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("clientes")
    .child(id);
};
export const grabaPuntosCliente = (id, value, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("clientes")
    .child(id)
    .child("puntos")
    .child(value)
    .set(data);
};

/////////////---------ubigeos-------------------
export const consultaubigeo = () => {
  return db.database().ref("ubigeos");
};
//-----------Acrhivos---------------
export const guardaArchivo = (BD, selectedFile, ubicacion) => {
  const storageRef = db.storage().ref(BD + "/" + ubicacion);
  return storageRef.put(selectedFile);
};
export const guardaIcono = (selectedFile, ubicacion) => {
  const storageRef = db.storage().ref("carpetaiconos/" + ubicacion);
  return storageRef.put(selectedFile);
};
export const guardaRutaIconos = (orden, array) => {
  return db
    .database()
    .ref("iconos")
    .child(orden)
    .set(array);
};
export const eliminaRutaIconos = (orden) => {
  return db
    .database()
    .ref("iconos")
    .child(orden)
    .remove();
};
export const eliminaIcono = (ubicacion) => {
  return db
    .storage()
    .ref("carpetaiconos/" + ubicacion)
    .delete();
};
export const consultaIconos = () => {
  return db.database().ref("iconos");
};
export const guardaRutaArchibo = (ruta, tipo) => {
  return db
    .database()
    .ref("general")
    .child("Rutas")
    .child(tipo)
    .set(ruta);
};
export const consultaArchivo = () => {
  return db
    .database()
    .ref("general")
    .child("Rutas");
};
export const bajarCartaPDF = (base) => {
  return db
    .database()
    .ref(base)
    .child("Rutas");
};
//--------------clientes------------
export const allEmpleados = () => {
  return db
    .database()
    .ref("general")
    .child("empleados");
};
export const nuevoEmpleados = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("empleados")
    .child(id)
    .set(array);
};
export const eliminaEmpleados = (id) => {
  return db
    .database()
    .ref("general")
    .child("empleados")
    .child(id)
    .remove();
};
export const buscaEmpleados = () => {
  return db
    .database()
    .ref("general")
    .child("empleados");
};
//--------------proveedores------------
export const allProveedor = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proveedor");
};
export const nuevoProveedor = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proveedor")
    .child(id)
    .set(array);
};
export const eliminaProveedor = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proveedor")
    .child(id)
    .remove();
};
export const buscaProveedor = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("proveedor");
};
//-------buscar Otra BASE---------
export const buscaProductosOtraBase = (bd, id) => {
  return db
    .database()
    .ref(bd)
    .child("productos")
    .child(id);
};
export const edita_producto_otraBD = (bd, id, campo, stock) => {
  return db
    .database()
    .ref(bd)
    .child("productos")
    .child(id)
    .child(campo)
    .set(stock);
};
export const grabarStockOtraBase = (bd, id, stock) => {
  return db
    .database()
    .ref(bd)
    .child("productos")
    .child(id)
    .child("stock")
    .set(stock);
};
export const nuevoProductoOtraBase = (bd, id, array) => {
  return db
    .database()
    .ref(bd)
    .child("productos")
    .child(id)
    .set(array);
};
export const allProductoOtraBase = (bd) => {
  return db
    .database()
    .ref(bd)
    .child("productos");
};
export const allComprobantesCabeceraOtraBase = (bd) => {
  return db
    .database()
    .ref(bd)
    .child("comprobantecabecera");
};
export const nuevoComprobantesCabeceraSerie = (bd, id, serie) => {
  return db
    .database()
    .ref(bd)
    .child("comprobantecabecera")
    .child(id)
    .child("serie")
    .set(serie);
};
//--------------usuarios-----------
export const nuevoUsuario = (id, array) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .set(array);
};
export const allUsuarios = () => {
  return db.database().ref("usuarios");
};
export const buscarUsuarios = (id) => {
  return db
    .database()
    .ref("usuarios")
    .child(id);
};
export const buscarUsuarios_bloqueo = (id) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .child("bloqueo");
};
export const set_bloqueo = (id, value) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .child("bloqueo")
    .set(value);
};
export const primerUsuario = (id, correo, pass) => {
  db.database()
    .ref("usuarios")
    .child(id)
    .child("correo")
    .set(correo);
  db.database()
    .ref("usuarios")
    .child(id)
    .child("pass")
    .set(pass);
};
export const nuevoCampoUsuario = (id, campo, data) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .child(campo)
    .set(data);
};
export const set_permisos = (id, campo, value) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .child(campo)
    .set(value);
};
//--------------flujocaja-------------
export const allflujo = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja");
};
export const elimina_all_flujo = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja")
    .remove();
};
export const nuevoflujo = (array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja")
    .push(array);
};
export const estadoFlujo = (id, estado) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja")
    .child(id)
    .child("estado")
    .set(estado);
};
export const edita_Flujo = (id, estado) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja")
    .child(id)
    .set(estado);
};
export const flujo_historial = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja_historial")
    .child(id);
};
export const allflujo_historial = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja_historial");
};
export const nuevoflujo_historial = (array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("flujocaja_historial")
    .push(array);
};
//----modo pago---------
export const allModoPago = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("modopago");
};
export const nuevoModoPago = (modo, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("modopago")
    .child(modo)
    .set(data);
};
export const eliminaModoPago = (modo) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("modopago")
    .child(modo)
    .remove();
};
//----cuentas bancos---------
export const allBancos = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("bancos");
};
export const nuevoBanco = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("bancos")
    .child(id)
    .set(array);
};
export const eliminaBanco = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("bancos")
    .child(id)
    .remove();
};

//-----------------------notaCredito DEbito
export const consulta_CabeceraNCD = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(id);
};
export const allCabeceraNCD = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera");
};
export const grabaCabeceraNCD = (numeracion, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .set(array);
};
export const grabaDetalleNCD = (numeracion, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("ncddetalle")
    .child(numeracion)
    .set(array);
};
export const consultaDetalleNCD = (numeracion) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("ncddetalle")
    .child(numeracion);
};
export const grabaEstadoComprobanteNCD = (numeracion, estado, data, hash) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("estado")
    .set(estado);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("mensajeSunat")
    .set(data);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("hash")
    .set(hash);
};
export const grabaAnulacionreferecia = (numeracion, estado, data) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("estado")
    .set(estado);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("comprobantecabecera")
    .child(numeracion)
    .child("mensajeSunat")
    .set(data);
};
export const grabaAnulacionrefereciaNC = (numeracion, estado, data) => {
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("estado")
    .set(estado);
  db.database()
    .ref(store.state.baseDatos.bd)
    .child("ncdcabecera")
    .child(numeracion)
    .child("mensajeSunat")
    .set(data);
};
//-------------------------RESUMEN DE ANULACION-----------------+
export const allCabeceraRA = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("racabecera");
};
export const grabaCabeceraRA = (numeracion, array) => {
  var c = db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("racabecera")
    .child(numeracion)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const grabaDetalleRA = (numeracion, array) => {
  var c = db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("radetalle")
    .child(numeracion)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const consultaDetalleRA = (numeracion) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("radetalle")
    .child(numeracion);
};
//-----------------------NOTIFICACIONES--------------------
export const grabaNotificacion = (bd, estado, mensaje) => {
  var c = db
    .database()
    .ref("notificaciones")
    .child(bd)
    .child("mensaje")
    .set(mensaje)
    .then(() => {
      db.database()
        .ref("notificaciones")
        .child(bd)
        .child("dialogoAlerta")
        .set(estado);
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const grabaactualizadialogo = (bd, estado, dialog) => {
  var c = db
    .database()
    .ref("notificaciones")
    .child(bd)
    .child(dialog)
    .set(estado)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const allnotificaciones = (bd) => {
  return db
    .database()
    .ref("notificaciones")
    .child(bd);
};
export const grabaCronogramapago = (bd, array) => {
  return db
    .database()
    .ref("pagoempresas")
    .child(bd)
    .set(array);
};
export const allCronogramapago = (bd) => {
  return db
    .database()
    .ref("pagoempresas")
    .child(bd);
};
export const grabapagocronograma = (bd, periodo, data, valor) => {
  return db
    .database()
    .ref("pagoempresas")
    .child(bd)
    .child(periodo)
    .child(data)
    .set(valor);
};
//-----------------------REPORTES--------------------
export const grabareporte = (tipo, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("reportes")
    .child(tipo)
    .set(array);
};
export const consultareporte = (tipo) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("reportes")
    .child(tipo);
};
//-----------------proformas-------------
export const allCabeceraProforma = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("proformas")
    .child("cabecera");
};
export const grabaCabeceraProforma = (numeracion, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("proformas")
    .child("cabecera")
    .child(numeracion)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const grabaDetalleProforma = (numeracion, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("proformas")
    .child("detalle")
    .child(numeracion)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const consultaDetalleProforma = (numeracion) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("proformas")
    .child("detalle")
    .child(numeracion);
};
//------------------cujentas x cobrar
export const nuevaCuentaxcobrar = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("x_cobrar")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const editaCuentaxCobrar = (id, tipo, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("x_cobrar")
    .child(id)
    .child(tipo)
    .set(data)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const allcuentaxcobrar = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("x_cobrar");
};
///----------------------guia remision-*-----------------
export const allGuiaremision = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("Guia");
};
export const nuevaGuiaremision = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("Guia")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const eliminaGuiaremision = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("Guia")
    .child(id)
    .remove();
};
export const buscaGuiaremision = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("Guia")
    .child(id);
};
export const editaGuiaremision = (id, campo, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("kardex")
    .child("Guia")
    .child(id)
    .child(campo)
    .set(data);
};

//--------------------almacen crea-------------------------
export const all_almacenes = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("almacen")
    .child("lista_almacenes");
};
export const elimina_almacenes = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("almacen")
    .child("lista_almacenes")
    .child(id)
    .remove();
};
export const nuevo_almacen = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("almacen")
    .child("lista_almacenes")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
};

//--------------- almacen Accesorios --  - -

export const allAccesorios = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("Accesorios");
};
export const nuevoAccesorios = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .set(array);
};
export const editaAccesorios = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .set(array);
};
export const eliminaAccesorios = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .remove();
};
//--------------- almacen Accesorios tienda otr bd--  - -

export const allAccesorios_t_bd = (bd) => {
  return db
    .database()
    .ref(bd)
    .child("almacen")
    .child("Accesorios");
};
export const busca_Accesorios_t_bd = (bd, id) => {
  return db
    .database()
    .ref(bd)
    .child("almacen")
    .child("Accesorios")
    .child(id);
};
export const nuevoAccesorios_t_bd = (bd, id, array) => {
  return db
    .database()
    .ref(bd)
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .set(array);
};
export const eliminaAccesorios_t_bd = (bd, id) => {
  return db
    .database()
    .ref(bd)
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .remove();
};
//--------------- almacen Accesorios tienda --  - -

export const allAccesorios_t = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("almacen")
    .child("Accesorios");
};
export const nuevoAccesorios_t = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .set(array);
};
export const eliminaAccesorios_t = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("almacen")
    .child("Accesorios")
    .child(id)
    .remove();
};

//------------------ pedidos---------------------
export const nuevo_pendiente = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("pendientes")
    .child(id)
    .set(array);
};
export const edita_pendiente = (id, campo, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("pendientes")
    .child(id)
    .child(campo)
    .set(data);
};
export const elimina_pendiente = (id, campo) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("pendientes")
    .child(id)
    .child(campo)
    .remove();
};
export const edita_pendiente_bd = (bd, id, campo, data) => {
  return db
    .database()
    .ref(bd)
    .child("pendientes")
    .child(id)
    .child(campo)
    .set(data);
};
export const busca_pendiente = (bd, id) => {
  return db
    .database()
    .ref(bd)
    .child("pendientes")
    .child(id);
};
export const allpendientes = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("pendientes");
};
//------------------ pedidos---------------------
export const nuevo_pedido = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("pedidos")
    .child(id)
    .set(array);
};
export const busca_pedido = (id) => {
  return db
    .database()
    .ref("general")
    .child("pedidos")
    .child(id);
};
export const edita_pedido = (id, campo, data) => {
  return db
    .database()
    .ref("general")
    .child("pedidos")
    .child(id)
    .child(campo)
    .set(data);
};
export const allpedidos = () => {
  return db
    .database()
    .ref("general")
    .child("pedidos");
};
//------------------ pedidos---------------------
export const nuevo_cita = (data) => {
  return db
    .database()
    .ref("general")
    .child("citas")
    .push(data);
};
export const elimina_cita = (id) => {
  return db
    .database()
    .ref("general")
    .child("citas")
    .child(id)
    .remove();
};
export const edita_cita = (id, data) => {
  return db
    .database()
    .ref("general")
    .child("citas")
    .child(id)
    .set(data);
};
export const allcitas = () => {
  return db
    .database()
    .ref("general")
    .child("citas");
};
export const busca_citas = (id) => {
  return db
    .database()
    .ref("general")
    .child("citas")
    .child(id);
};
//--------------contadores generales
export const obtenContador_externo = () => {
  return db
    .database()
    .ref("general")
    .child("contadores");
};
export const actualizaContador_externo = (contador, orden) => {
  return db
    .database()
    .ref("general")
    .child("contadores")
    .child(contador)
    .set(orden);
};
//--------------clientes------------
export const allClientes = () => {
  return db
    .database()
    .ref("general")
    .child("clientes");
};
export const nuevoCliente = (id, array) => {
  array.modificado = moment().unix();
  guardar_local("clientes", array);
  return db
    .database()
    .ref("general")
    .child("clientes")
    .child(id)
    .set(array);
};
export const eliminaCliente = (id) => {
  return db
    .database()
    .ref("general")
    .child("clientes")
    .child(id)
    .remove();
};
export const buscaCliente = (id) => {
  return db
    .database()
    .ref("general")
    .child("clientes")
    .child(id);
};
export const edita_campo_Cliente = (id, campo, data) => {
  db.database()
    .ref("general")
    .child("clientes")
    .child(id)
    .child("modificado")
    .set(moment().unix());
  return db
    .database()
    .ref("general")
    .child("clientes")
    .child(id)
    .child(campo)
    .set(data);
};
//-------------------- agrega medidcion
export const all_mediciones = () => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion");
  // return db.database().ref("general").child("optometrias").child("medicion")
};
export const busca_medicion = (id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion")
    .child(id);
};
export const elimina_medicion = (id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion")
    .child(id)
    .remove();
};
export const edita_medicion = (id, campo, data) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion")
    .child(id)
    .child(campo)
    .set(data);
};
export const nuevo_medicion = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion")
    .child(id)
    .set(array);
};
//-------------------- agrega control
export const all_control = () => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("control");
  // return db.database().ref("general").child("optometrias").child("medicion")
};
export const busca_control = (id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("control")
    .child(id);
};
export const elimina_control = (id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("control")
    .child(id)
    .remove();
};
export const edita_control = (id, campo, data) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("control")
    .child(id)
    .child(campo)
    .set(data);
};
export const nuevo_control = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("control")
    .child(id)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
};
//-----------------RECETA EXTERNA ------------------------
export const nuevaReceta_externa = (dni, id, array) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion_externa")
    .child(dni)
    .child(id)
    .set(array);
};
export const busca_receta_externa = (dni, id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion_externa")
    .child(dni)
    .child(id);
};
export const allRecente_externa = (dni) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("medicion_externa")
    .child(dni);
};
///-----------------agrega tablaa------------------
export const agrega_tabla = (tabla, array) => {
  return db
    .database()
    .ref("general")
    .child("tablas")
    .child(tabla)
    .set(array)
    .then(() => {
      return true;
    })
    .catch(function(error) {
      return error;
    });
  return c;
};
export const busca_tabla = (tabla) => {
  return db
    .database()
    .ref("general")
    .child("tablas")
    .child(tabla);
};
//----------------all tiendas---------------------
export const all_tienda = () => {
  return db
    .database()
    .ref("general")
    .child("tiendas");
};
export const nueva_tienda = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("tiendas")
    .child(id)
    .set(array);
};
export const elimina_tienda = (id) => {
  return db
    .database()
    .ref("general")
    .child("tiendas")
    .child(id)
    .remove();
};
//-----------------PARAMETROS -------------------------------
export const nuevo_parametro = (dni, id, array) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("parametro")
    .child(dni)
    .child(id)
    .set(array);
};
export const busca_parametros = (dni, id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("parametro")
    .child(dni)
    .child(id);
};
export const allparametros = (dni) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("parametro")
    .child(dni);
};
//-----------------PARAMETROS CABECERA -------------------------------
export const nueva_cabecera_parametro = (dni, id, array) => {
  db.database()
    .ref("general")
    .child("optometria")
    .child("parametros_pendientes")
    .child(id)
    .set(array);
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("cabe_parametro")
    .child(dni)
    .child(id)
    .set(array);
};
export const edita_cabecera_parametro = (dni, id, campo, data) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("cabe_parametro")
    .child(dni)
    .child(id)
    .child(campo)
    .set(data);
};
export const busca_cabecera_parametros = (dni, id) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("cabe_parametro")
    .child(dni)
    .child(id);
};
export const all_cabecera_parametros = (dni) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("cabe_parametro")
    .child(dni);
};
export const all_pendientes_parametros = () => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("parametros_pendientes");
};
export const edita_pendientes_parametro = (id, campo, data) => {
  return db
    .database()
    .ref("general")
    .child("optometria")
    .child("parametros_pendientes")
    .child(id)
    .child(campo)
    .set(data);
};
//--------------- almacen productos --  - -
export const busca_Productos = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("productos")
    .child(id);
};
export const allProductos = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("productos");
};
export const nuevoProductos = (id, array) => {
  /*array.modificado = moment().unix();
  guardar_local("productos", array);*/
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("productos")
    .child(id)
    .set(array);
};
export const eliminaProductos = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("productos")
    .child(id)
    .remove();
};
//--------------- almacen lentes contacto --  - -
export const busca_lentes_c = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("lentes_c")
    .child(id);
};
export const all_lentes_c = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("lentes_c");
};
export const nuevo_lente_c = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("lentes_c")
    .child(id)
    .set(array);
};
export const elimina_lente_c = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("lentes_c")
    .child(id)
    .remove();
};
//------------------kardex
export const nuevoMovimiento = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("kardex")
    .child("movimientos")
    .child(id)
    .set(array);
};
export const edita_Movimiento = (id, campo, data) => {
  return db
    .database()
    .ref("general")
    .child("kardex")
    .child("movimientos")
    .child(id)
    .child(campo)
    .set(data);
};
export const allMovimientos = () => {
  return db
    .database()
    .ref("general")
    .child("kardex")
    .child("movimientos");
};
export const consulta_Movimientos = (id) => {
  return db
    .database()
    .ref("general")
    .child("kardex")
    .child("movimientos")
    .child(id);
};
export const elmina_mov_kardex = (id) => {
  return db
    .database()
    .ref("general")
    .child("kardex")
    .child("movimientos")
    .child(id)
    .remove();
};
//------------------GARANTIAS
export const nueva_garantia = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("garantias")
    .child(id)
    .set(array);
};
export const all_garantias = () => {
  return db
    .database()
    .ref("general")
    .child("garantias");
};
//------------------GARANTIAS Pendientes
export const nueva_garantia_p = (array) => {
  return db
    .database()
    .ref("general")
    .child("garantias_pendientes")
    .push(array);
};
export const all_garantias_p = () => {
  return db
    .database()
    .ref("general")
    .child("garantias_pendientes");
};
export const edita_garantias_p = (campo, data) => {
  return db
    .database()
    .ref("general")
    .child("garantias_pendientes")
    .child(campo)
    .set(data);
};
//--------------- almacen monturas --  - -

export const allMonturas = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas");
};
export const busca_Monturas = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas")
    .child(id);
};
export const nuevaMontura = (id, array) => {
  /* array.modificado = moment().unix()
    guardar_local('monturas', array)*/
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas")
    .child(id)
    .set(array);
};
export const editaMontura = (id, array) => {
  db.database()
    .ref("general")
    .child("almacen")
    .child("monturas")
    .child(id.split("/")[0].trim())
    .child("modificado")
    .set(moment().unix());
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas")
    .child(id)
    .set(array);
};
export const eliminaMontura = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas")
    .child(id)
    .remove();
};
//--------------- almacen monturas vendidas--  - -

export const allMonturas_v = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas_v");
};
export const busca_Monturas_v = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas_v")
    .child(id);
};
export const nuevaMontura_v = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas_v")
    .child(id)
    .set(array);
};
export const editaMontura_v = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas_v")
    .child(id)
    .set(array);
};
export const eliminaMontura_v = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("monturas_v")
    .child(id)
    .remove();
};
//--------------- cuadre monturas --  - -
export const all_pp_cuadre = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("cuadre")
    .child(id)
    .child("invent_actual");
};
export const allcuadre = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("cuadre");
};
export const nuevacuadre = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("cuadre")
    .child(id)
    .set(array);
};
export const editacuadre = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("cuadre")
    .child(id)
    .set(array);
};
export const eliminacuadre = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("cuadre")
    .child(id)
    .remove();
};
//--------------- traslados --  - -

export const all_traslados = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("traslados");
};
export const nueva_traslado = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("traslados")
    .child(id)
    .set(array);
};
export const edita_traslado = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("traslados")
    .child(id)
    .set(array);
};
export const elimina_traslado = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("traslados")
    .child(id)
    .remove();
};
//--------------- envios --  - -

export const all_envios = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios");
};
export const nueva_envios = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios")
    .child(id)
    .set(array);
};
export const edita_envios = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios")
    .child(id)
    .set(array);
};
export const elimina_envios = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios")
    .child(id)
    .remove();
};
//--------------- envios accesorios --  - -

export const all_envios_a = () => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios_a");
};
export const nueva_envios_a = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios_a")
    .child(id)
    .set(array);
};
export const edita_envios_a = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios_a")
    .child(id)
    .set(array);
};
export const elimina_envios_a = (id) => {
  return db
    .database()
    .ref("general")
    .child("almacen")
    .child("envios_a")
    .child(id)
    .remove();
};

//--------------- historico General --  - -

export const all_historial = (id) => {
  return db
    .database()
    .ref("general")
    .child("historial_clientes")
    .child(id);
};
export const nuevo_historial = (id, array) => {
  return db
    .database()
    .ref("general")
    .child("historial_clientes")
    .child(id)
    .push(array);
};
export const elimina_historial = (id) => {
  return db
    .database()
    .ref("general")
    .child("historial_clientes")
    .child(id)
    .remove();
};
//--------------- historico mensajeria --  - -

export const all_mensajeria = () => {
  return db
    .database()
    .ref("general")
    .child("mensajes");
};
export const nuevo_mensaje = (array) => {
  return db
    .database()
    .ref("general")
    .child("mensajes")
    .push(array);
};
export const elimina_mensaje = (id) => {
  return db
    .database()
    .ref("general")
    .child("mensajes")
    .child(id)
    .remove();
};
//metas
export const nueva_meta = (periodo, array) => {
  return db
    .database()
    .ref("general")
    .child("metas")
    .child(periodo)
    .set(array);
};
export const ver_meta = (periodo) => {
  return db
    .database()
    .ref("general")
    .child("metas")
    .child(periodo);
};
export const all_grupo = () => {
  return db
    .database()
    .ref("general")
    .child("grupos");
};
export const nuevo_grupo = (array) => {
  return db
    .database()
    .ref("general")
    .child("grupos")
    .push(array);
};
export const elimina_grupo = (id) => {
  return db
    .database()
    .ref("general")
    .child("grupos")
    .child(id)
    .remove();
};
export const marca_asis = (data) => {
  return db
    .database()
    .ref("general")
    .child("asistencia")
    .child("marcacion")
    .push(data);
};
export const lee_marca_asis = () => {
  return db
    .database()
    .ref("general")
    .child("asistencia")
    .child("marcacion");
};
export const marca_asis_personal = (id, data) => {
  return db
    .database()
    .ref("general")
    .child("asistencia")
    .child("personal")
    .child(id)
    .push(data);
};
